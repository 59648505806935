<template>
  <div class="educational-process">
    <div v-if="!mobileMode" class="education-process__content page">
      <component :is="currentComponent"></component>
    </div>
    <aside-bar>
      <div class="educational-process__controls-list">
        <template v-if="mobileMode">
          <m-collapse
            :blockShowBody="button.href ? true : undefined"
            class="m-collapse--controls-list"
            v-for="(button, idx) of filtredControlButtons"
            :key="idx"
          >
            <template v-slot:header>
              <div
                @click="handleButtonClick(button)"
                class="header__content-wrp"
              >
                <span class="header__text">{{ button.name }}</span>
              </div>
            </template>
            <template v-slot:body>
              <div class="body__content">
                <component
                  :changeViews="false"
                  :is="button.component"
                ></component>
              </div>
            </template>
          </m-collapse>
        </template>
        <template v-else>
          <button
            @click="handleButtonClick(button)"
            :class="[
              'controls-list__item',
              {
                'controls-list__item--active':
                  currentComponent === button.component,
              },
            ]"
            v-for="button of filtredControlButtons"
            :key="button.id"
          >
            {{ button.name }}
          </button>
        </template>
      </div>
    </aside-bar>
  </div>
</template>

<script>
import asideBar from '@/components/asideBar';
import disciplines from './modules/disciplines/index.vue';
import creditBook from './modules/creditBook/index.vue';
import library from './modules/library.vue';
import antiPlagiarism from './modules/antiPlagiarism.vue';
import electronicJournal from './modules/electronicJournal';

export default {
  data() {
    return {
      currentComponent: 'disciplines',
      controlButtons: [
        {
          id: 1,
          name: 'Дисциплины',
          component: 'disciplines',
        },
        {
          id: 2,
          name: 'Зачетная книжка',
          component: 'creditBook',
        },
        {
          id: 3,
          name: 'Библиотека',
          component: 'library',
        },
        {
          id: 4,
          name: 'Антиплагиат',
          component: 'antiPlagiarism',
        },
        {
          id: 6,
          name: 'Дополнительное образование',
          href: 'https://open-college.ru/education/skills/',
        },
        {
          id: 7,
          name: 'Электронный журнал',
          component: "electronicJournal",
        },
      ],
    };
  },

  components: {
    'aside-bar': asideBar,
    disciplines,
    creditBook,
    library,
    antiPlagiarism,
    electronicJournal,
  },

  computed: {
    isTestUser() {
      return ['26738'].includes(
        this.$store.state.auth.user.uInstudy_id,
      );
    },

    mobileMode() {
      return this.$store.state.mobileMode;
    },

    filtredControlButtons() {
      if (this.isTestUser) {
        return this.controlButtons;
      }

      return this.controlButtons.filter((el) => el.name !== 'Электронный журнал');
    },
  },

  methods: {
    handleButtonClick(button) {
      if (button.href) return window.open(button.href, '_blank');
      this.currentComponent = button.component;
    },
  },
};
</script>

<style lang="scss">
.educational-process {
  overflow-y: hidden;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  .educational-process__controls-list {
    display: grid;
    row-gap: 2.4rem;
  }
  .education-process__content {
    border-radius: 2rem 0 0 0;
    height: 100%;
    overflow: auto;
    padding: 4rem 1rem 0 4rem;
  }
  .controls-list__item {
    transition-duration: 0.3s;
    transition-property: color, background-color;
    width: 24.4rem;
    padding: 1.1rem;
    border-radius: 1rem;
    background-color: $basic-white;
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    color: $brand-clr-1;
  }
  .controls-list__item--active {
    background-color: $brand-clr-1;
    color: $basic-white;
  }
}
@include breakPoints(md) {
  .educational-process {
    background-color: $basic-white;
    .aside {
      background-color: transparent;
    }
    .simplebar-content {
      padding: 0 !important;
    }
    .aside__body {
      padding: 0 2rem;
      width: 100%;
      justify-content: center;
    }
    .educational-process__controls-list {
      width: 100%;
    }
    .m-collapse--controls-list {
      border-radius: 1rem;
    }
    .m-collapse__header {
      padding: 1.5rem;
      background-color: #f4f4f6;
    }
    .header__text {
      color: $basic-black;
      font-size: 1.4rem;
      text-transform: uppercase;
      font-family: "Gilroy-Bold";
    }
    .m-collapse__header-icon {
      color: $basic-black;
    }
    .body__content {
      padding: 3rem 0;
    }
    .header__display-btn {
      display: none;
    }
    .documents__header {
      grid-template-columns: auto 1fr;
    }
  }
}
</style>
