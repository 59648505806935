<template>
  <div class="library">
    <h3 class="library__header">Антиплагиат</h3>
    <img
      class="library__logo"
      src="@/assets/img/services/anti-plagism-logo.png"
      alt="platform-logo"
    />
    <div class="library__buttons">
      <a
        href="https://www.antiplagiat.ru"
        target="blank"
        class="library__button library__button--filling"
      >
        Перейти в антиплагиат
      </a>
      <!--<button class="library__button library__button--no-filling">
        <m-icon class="" name="file" /> Инструкция по работе с антиплагиатом
      </button>!-->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.library {
  @extend %library_and_plagism;
}
@include breakPoints(md) {
  .library {
    padding-right: 0;
    .library__buttons {
      justify-content: center;
    }
  }
}
</style>
