<template>
  <div class="tasks-module">
    <m-collapse
      :blockShowBody="true"
      :class="'m-collapse--status-' + task.status"
      v-for="(task, index) of list"
      :key="index"
    >
      <template v-slot:header>
        <div class="header__content-wrp">
          <span class="header__text"> {{ task.name }}</span>
          <button
            @click="runTest(task)"
            :class="['header__status', setStatus(task).class]"
          >
            <m-icon name="lock" />
            {{ setStatus(task).status }}
          </button>
          <span v-if="task.note">{{ task.note }}</span>
        </div>
      </template>
      <template v-slot:body>
        <div class="body__content">
          <textarea
            placeholder="Коментарий к работе"
            v-model="task.note"
            class="body__text"
          ></textarea>
          <p v-if="task.file === '1'" class="body__upload-file">
            <span class="upload-file__text">Прикрепить файл</span>
            <input
              multiple
              class="upload-file__input"
              @change="handleUpload(task.id)"
              :ref="'upload-file-' + task.id"
              type="file"
            />
          </p>

          <ul class="body__upload-list">
            <li
              class="upload-list__item"
              v-for="(file, idx) of files"
              :key="idx"
            >
              <m-icon name="file-no-background" />{{ file.name }}
            </li>
          </ul>
          <button @click="sendFiles" class="body__send-files btn-fill">
            Отправить на проверку
          </button>
        </div>
      </template>
    </m-collapse>

    <TaskModulePractice
      v-if="practicePlaceIsShow && practiceData.practiceDiscipline !== null"
      :practice-data="practiceData"
      @change="onChange"
    />
    <TaskModuleCoursework v-if="false" @select="onSelect" />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import headerDocuments from '@/components/documents/header.vue';
import TaskModulePractice from '@/views/EducationalProcess/discipline/modules/tabs/tasks/TasksModule/TaskModulePractice.vue';
import TaskModuleCoursework from '@/views/EducationalProcess/discipline/modules/tabs/tasks/TasksModule/TaskModuleCoursework.vue';

export default {
  name: 'TasksModule',

  components: {
    headerDocuments,
    TaskModulePractice,
    TaskModuleCoursework,
  },

  props: {
    list: {
      type: Array,
      default: () => [],
    },

    practiceData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      files: [],
      headerData: {
        search: '',
        displayMode: 'list',
        sort: 'all',
        options: [
          {
            value: 'all',
            label: 'Все файлы',
          },
          {
            value: 'favorites',
            label: 'Избранное',
          },
          {
            value: 'date',
            label: 'По дате',
          },
        ],
      },
    };
  },

  async mounted() {
    await this.getCourseWorkList();
  },

  computed: {
    ...mapState({
      courseWorkList: (state) => state.discipline.courseWorkList,
    }),

    practicePlaceIsShow() {
      return (this.isTestUser || this.usersInTheTestGroup) && this.practiceData.practiceDiscipline !== null
    },

    userInfo() {
      return this.$store.state.userInfo;
    },

    disciplineData() {
      return this.$store.state.discipline.disciplineData;
    },

    isTestUser() {
      return ['19295', '18711', '23830', '23908'].includes(this.$store.state.auth.user.uInstudy_id);
    },

    usersInTheTestGroup() {
      return ['00731'].includes(this.$store.state.auth.user.group1c_id)
    }
   },

  methods: {
    ...mapMutations(['setCurrentModalComponent', 'pushNotify']),

    ...mapActions({
      getMaterials: 'discipline/getMaterials',
      getCourseWorkList: 'discipline/getCourseWorkList',
    }),

    onSelect() {
      this.setCurrentModalComponent({
        name: 'selectCourseworkModal',
        data: {
          list: this.courseWorkList,
          cb: this.onSaveCoursework,
        },
      });
    },

    onSaveCoursework(idx, newThemeName) {
      console.log(idx, newThemeName);
    },

    async onChange(value) {
      try {
        const { status } = await this.axios.post('/api/practice-place', {
          practice_discipline: this.practiceData.practiceDiscipline,
          practice_period: this.practiceData.practicePeriod,
          practice_place: value,
        });

        if (status) {
          this.pushNotify({
            content: 'Название места практики изменено',
            type: 'success',
          });

          await this.getMaterials(
            JSON.parse(localStorage.getItem('selectedDiscipline')),
          );
        } else {
          this.pushNotify({
            content: 'Ошибка',
            type: 'error',
          });
        }
      } catch (e) {
        console.warn('Fetch failed [TaskModule/onChange]', e);
      }
    },

    runTest(value) {
      if (value.enable) {
        window.open(
          `https://college.instudy.online/apiIS/work/?token=${value.token}&partable=${this.$store?.state?.discipline?.disciplineData?.partableFlag}`,
          '_blank',
        );
      }
    },

    setStatus(value) {
      if (!value.enable) {
        if (value.result === null || !value.result) {
          return { class: 'header__status--disable', status: value.result };
        }
        return { class: 'header__status--credited', status: value.result };
      }
      if (value.done === null) {
        if (+value.file === '0') {
          return {
            class: 'header__status--not-attached',
            status: 'Сдать тест',
          };
        }
        return {
          class: 'header__status--not-attached',
          status: 'Сдать работу',
        };
      }
      if (+value.done === 0) {
        return {
          class: 'header__status--expired',
          status: 'Работа не принята',
        };
      }
      if (+value.done === 1) { return { class: 'header__status--completed', status: value.result }; }
      return { class: 'header__status--disable', status: '' };
    },

    handleUpload(id) {
      this.$refs[`upload-file-${id}`][0].files.forEach((file) => {
        this.files.push(file);
      });
    },

    sendFiles() {
      console.log('send');
    },

    callBackF() {
      this.sendFiles();
      this.setCurrentModalComponent({ name: null, data: null });
    },

    runModal() {
      const data = {
        name: 'comentsModal',
        data: {
          callBack: this.callBackF,
          task: this.task,
        },
      };
      this.setCurrentModalComponent(data);
    },
  },
};
</script>

<style lang="scss">
.tasks-module {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .el-select {
    .el-input__inner {
      background-color: $basic-white;
    }
  }
  .m-icon--lock {
    width: 1.5rem;
    display: none;
  }
  .m-collapse {
    border-radius: 1rem;
    background-color: $basic-white;
  }
  .m-collapse__header {
    padding: 1.6rem;
    background-color: transparent;
  }
  .header__content-wrp {
    display: grid;
    grid-template-columns: 5fr 3fr;
    column-gap: 1.6rem;
    align-items: center;
    padding-right: 2rem;

    @include breakPoints(sm) {
      grid-template-columns: 1fr;
      row-gap: 1.6rem;
    }
  }
  .header__text {
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    color: $basic-black;
    text-transform: uppercase;
  }
  .header__status {
    font-size: 1.3rem;
    font-family: "Gilroy-Bold";
    color: $basic-black;
    text-transform: uppercase;
    width: 22rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 2;

    @include breakPoints(sm) {
      grid-row-start: unset;
      grid-row-end: unset;
      grid-column-start: unset;
      grid-column-end: unset;
    }
  }
  .m-collapse__header-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: $basic-white;
    background-color: $brand-clr-1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header__status--credited {
    background-color: $brand-clr-4;
  }

  .header__status--disable {
    background-color: $placeholder-text-clr;
    position: relative;
    .m-icon--lock {
      display: block;
    }
  }
  .header__status--not-attached {
    background-color: transparent;
    border: 1px solid $brand-clr-1;
  }
  .header__status--expired {
    background-color: #ff6c46;
  }
  .header__status--completed {
    background-color: #befaad;
  }
  .body__content {
    display: grid;
    padding: 1.6rem;
    padding-top: 1rem;
    grid-template-columns: 5.2fr 1fr 3fr;
    column-gap: 1.6rem;
    grid-template-areas:
      "text comment-btn upload-file send-file"
      "upload-list comment-btn upload-file send-file";
  }
  .body__text {
    background: linear-gradient(90deg, #f5f3f9 0%, #e2dded 100%);
    grid-area: text;
    color: $basic-black;
    font-family: "ProximaNova-Regular";
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
    resize: none;
    border: 1px solid $basic-white;
    border-radius: 1rem;
    outline: none;
    padding: 0.5rem;
    min-height: 6rem;
    box-shadow: 0px 0px 5px 1px #c4c4c4;
    &::placeholder {
      font-family: "ProximaNova-Regular";
    }
  }
  .body__send-files {
    grid-area: send-file;
  }
  .body__comment-btn {
    background-color: transparent;
    width: max-content;
    height: max-content;
    align-self: center;
    justify-self: center;
    grid-area: comment-btn;
    i {
      font-size: 2rem;
      color: $brand-clr-1;
    }
  }
  .body__upload-file {
    grid-area: upload-file;
    position: relative;
    cursor: pointer;
    border: 1px solid $brand-clr-1;
    border-radius: 1rem;
    width: 15rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .body__upload-list {
    grid-area: upload-list;
    display: grid;
    row-gap: 0.4rem;
  }
  .upload-list__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.8rem;
    color: $basic-black;
    font-family: "ProximaNova-Regular";
  }
  .m-icon--file-no-background {
    fill: #cccccc;
  }
  .upload-file__text {
    position: relative;
    font-family: "Gilroy-Bold";
    color: $brand-clr-1;
    z-index: 1;
    font-size: 1.4rem;
  }
  .upload-file__input {
    z-index: 5;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .m-collapse--status-3,
  .m-collapse--status-2 {
    .m-collapse__header-icon {
      background: #e3ddfa;
      color: $brand-clr-1;
    }
  }
}
</style>
