<template>
  <div class="home">
    <simplebar class="home__content" data-simplebar-auto-hide="false">
      <div class="flex-container">
        <div class="home__left-section">
          <router-link
            v-if="credit !== null"
            v-html="getCorrectCredit().value"
            :style="{ backgroundColor: getCorrectCredit().color }"
            class="top__link"
            to="/"
          >
          </router-link>
          <button class="top__link top__link--isPurple" @click="getTicket">
            ОПЛАТА ПО QR
          </button>

          <div class="calendar-box">
            <sweet-calendar
              :eventCategories="eventCategories"
              :events="events"
              :firstDayOfWeek="2"
              :offDays="[1, 7]"
              @nextMonth="handleChangeMonth"
              @prevMonth="handleChangeMonth"
              v-model="selectedDay"
            >
            </sweet-calendar>

            <button class="btn-fill home__calendar-btn" @click="showSincModal">
              <m-icon name="calendar"/>
              Синхронизировать календарь
            </button>

            <m-collapse
              ref="collapse-calendar"
              class="home__collapse collapse-calendar"
            >
              <template v-slot:header>
                <div class="header__content-wrp">
                  <span class="header__text">Вебинары</span>
                </div>
              </template>

              <template v-slot:body>
                <div class="body__content">
                  <template v-if="filtredEvents.length">
                    <m-collapse
                      :class="['collapse-calendar--inner']"
                      v-for="(event, idx) of filtredEvents"
                      :key="idx"
                    >
                      <template v-slot:header>
                        <div class="header__content-wrp">
                          <p class="header__text">
                            <span>
                              {{ event.time_begin }}-{{ event.time_end }}</span
                            >
                            <b>{{ event.discipline }}</b>
                          </p>
                        </div>
                      </template>
                      <template v-slot:body>
                        <div class="body__content">
                          <div class="content__wrp">
                            <span class="content__lesson">{{
                              event.room
                            }}</span>
                            <span class="content__teacher-name">{{
                              event.teacher
                            }}</span>
                          </div>
                          <a
                            class="content__link"
                            v-if="event.href"
                            target="blank"
                            :href="setLink(event)"
                            >Перейти к событию <m-icon name="arrow"
                          /></a>
                        </div>
                      </template>
                    </m-collapse>
                  </template>

                  <template v-else>
                    <span class="notify-text">Нет событий в этот день</span>
                  </template>
                </div>
              </template>
            </m-collapse>
          </div>
        </div>
        <div class="home-right-section">
          <div :class="['progress-tab', { more: !navigationMax }]">
            <HomeSessionProgress v-if="isTestUser && sessionData" :session-data="sessionData"/>

            <m-collapse
              v-if="announces && announces.length"
              :manualShow="true"
              class="home__collapse home__collapse--progress"
            >
              <template v-slot:header>
                <div class="header__content-wrp">
                  <span class="header__text">Объявления</span>
                </div>
              </template>
              <template v-slot:body>
                <div class="body__content">
                  <m-collapse
                    :class="['home__collapse--advertisement']"
                    v-for="(announce, index) of announces"
                    :key="index"
                  >
                    <template v-slot:header>
                      <div class="header__content-wrp">
                        <p class="header__text">
                          <b>{{ announce.title }} |</b>
                          <span> {{ announce.date }}</span>
                        </p>
                      </div>
                    </template>
                    <template v-slot:body>
                      <div class="body__content">
                        <div :class="['content__status']"></div>
                        <p class="content__text" v-html="announce.text"></p>
                      </div>
                    </template>
                  </m-collapse>
                </div>
              </template>
            </m-collapse>
          </div>
        </div>
      </div>
    </simplebar>
    <transition name="slide-fade-right">
      <aside-bar
        v-if="!mobileMode"
        :componentsList="asideList"
        header="userTab"
        class="asside-bar"
      >
      </aside-bar>
    </transition>
  </div>
</template>

<script>
import DateTime from '@/modules/dateTime';
import simplebar from 'simplebar-vue';
import asideBar from '@/components/asideBar/index';
import sweetCalendar from '@/components/sweetCalendar';
import HomeSessionProgress from '@/components/pages/Home/HomeSessionProgress';
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  name: 'Home',

  components: {
    'sweet-calendar': sweetCalendar,
    'aside-bar': asideBar,
    simplebar,
    HomeSessionProgress,
  },

  data() {
    return {
      selectedDay: new DateTime(),
      showVebinarList: true,
      showNotificationList: true,
      prevTaskVebinar: [2, 3, 4, 7],
      prevTaskNotification: [1, 8],
      vebinars: [],
      searchText: '',
      eventCategories: {
        event: 'is-event',
        selected: 'is-selected',
        now: 'is-now',
      },
    };
  },

  watch: {
    filtredEvents(newValue) {
      if (newValue.length) {
        this.$refs['collapse-calendar'].showBody = true;
      } else {
        this.$refs['collapse-calendar'].showBody = false;
      }
    },
  },

  computed: {
    asideList() {
      return [
        'curatorTab',
        'buttonTab',
        'navigationTab',
        ...this.haveFullTimeForm() ? ['supportTab'] : []
      ]
    },
    studentInfo() {
      return this.$store.state.userInfo;
    },

    mobileMode() {
      return this.$store.state.mobileMode;
    },

    announces() {
      return this.$store.state.announces;
    },

    credit() {
      return this.$store.state.credit;
    },

    getDate() {
      const date = new Date();
      const dateYear = date.getFullYear();
      const dateMonth = date.getMonth() + 1;
      return `${dateYear}-${dateMonth < 10 ? `0${dateMonth}` : dateMonth}`;
    },

    events() {
      return this.$store.state.shedule.eventsForTheMonth;
    },

    filtredEvents() {
      return this.selectedDay
        ? this.events.filter(
          (event) => event.date === this.selectedDay.getYYYYMMDD(),
        )
        : [];
    },

    isTestUser() {
      return ['19295', '18711'].includes(
        this.$store.state.auth.user.uInstudy_id,
      );
    },

    sessionProgressIsShow() {
      return this.sessionData && this.studentInfo.form === 'Очно-заочная форма обучения';
    },

    navigationMax() {
      return this.$store.state.navigationMax;
    },

    sessionData() {
      return this.$store.state.sessionInfo;
    },

    credit() {
      return this.$store.state.credit;
    },
  },

  beforeMount() {
    if (!this.$store.state.shedule.month) this.getMonthlySchedule(this.getDate);
  },

  mounted() {
    const data = [];
    for (let i = 0; i <= 15; i++) {
      data.push({
        id: i,
        progress: i % 2 === 0,
      });
    }
    this.vebinars = data;
  },

  methods: {
    ...mapGetters(['getCorrectCredit', 'haveFullTimeForm']),
    ...mapActions({
      getSchedule: 'shedule/getSchedule',
      getMonthlySchedule: 'shedule/getMonthlySchedule',
    }),

    ...mapMutations(['setCurrentModalComponent']),

    showSincModal() {
      this.setCurrentModalComponent({
        name: 'syncCalendarModal',
        data: {},
      });
    },

    getTicket() {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://spo.instudy.online/api/payment_ticket', true);
      xhr.withCredentials = true;
      xhr.responseType = 'blob';
      xhr.setRequestHeader('token', 'token');
      xhr.onload = function (e) {
        if (this.status == 200) {
          const blob = new Blob([this.response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          window.open(url);
        }
      };
      xhr.send();
    },

    setLink(event) {
      if (event.token) return event.hrefList?.webinar;
      if (event.record) return event.record;
      return event.href;
    },

    plural(number) {
      if (!number) return '';
      return this.$helpers.helperFunctions.plural(number, [
        'день',
        'дня',
        'дней',
      ]);
    },

    handlrClick() {
      window.open(
        'https://spo.instudy.online/course/player/play.php',
        '_blank',
      );
    },

    handleChangeMonth(date) {
      this.getMonthlySchedule(date.getYYYYMM());
    },

    sessionPercent(data) {
      const currentDate = +data.currentDate;
      const startCourse = +data.startCourse;
      const startSession = +data.startSession;
      const firstNumber = startSession - startCourse;
      const secondNumber = currentDate - startCourse;
      if (firstNumber < secondNumber && secondNumber > 0) {
        return 0;
      }
      return +this.$helpers.helperFunctions
        .percentageOfTheNumber(firstNumber, secondNumber)
        .toFixed();
    },
  },
};
</script>

<style lang="scss">
.home {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: auto 30rem;
  position: relative;

  .home__left-section {
    max-width: 27.8rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .home-right-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    height: 100%;
    max-width: 50%;
    min-width: 30%;
  }

  .is-selected {
    background-color: $brand-clr-1;
    .day-number {
      color: white !important;
    }
  }

  .is-now {
    background-color: #dddee2 !important;
  }

  .is-now.is-event {
    border: 0.1rem solid #dddee2 !important;
    background: transparent !important;
    .day-number {
      background-color: #dddee2;
      color: $brand-clr-1 !important;
    }
  }

  .is-event.is-selected {
    border: 0.1rem solid $brand-clr-1 !important;
    background: transparent !important;
    .day-number {
      background-color: $brand-clr-1;
      color: white !important;
    }
  }

  .is-now.is-selected {
    background-color: $brand-clr-1 !important;
    .day-number {
      color: white !important;
    }
  }

  .is-event {
    border: 0.1rem solid $brand-clr-1 !important;
  }

  .simplebar-vertical {
    transform: translateX(-1rem) translateY(1rem);
    height: calc(100% - 2rem);
  }

  .home__content {
    height: 100%;
    border-radius: 2rem 0 0 0;
    background-color: $basic-white;
    padding: 2.4rem 4rem 2.4rem 4rem;
    overflow-y: auto;
  }

  .home__calendar-btn {
    display: flex;
    border-radius: 1rem 1rem 0 0;

    .m-icon {
      width: 1.5rem;
      margin-right: 1rem;
      fill: $basic-white;
    }
  }

  .flex-container {
    row-gap: 1.6rem;
    column-gap: 2.4rem;
    display: flex;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
  }

  .top__link {
    width: 27.8rem;
    padding: 0.5rem;
    background-color: $brand-clr-4;
    border-radius: 0.8rem;
    color: $basic-black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    font-family: "Gilroy-Regular";
    span {
      color: inherit;
      font-family: inherit;
    }
    b {
      font-family: "Gilroy-Bold";
      color: $basic-black;
    }
  }

  .top__link--isPurple {
    background-color: $brand-clr-1;
    color: $basic-white;
    font-size: 1.4rem;
  }

  .m-input {
    min-width: 27.8rem;
    max-width: 43.1rem;
    width: 50%;
    .m-icon {
      width: 1.7rem;
      fill: $basic-white;
    }
    .m-input__icon--isLeft {
      left: 1.9rem;
    }
    .icon-input-isLeft {
      padding-left: 1.6rem;
    }
    .m-input__input::placeholder {
      padding-left: 5rem;
    }
    .m-input__input:focus + .m-input__icon--isLeft {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  .progress-tab {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
    min-width: 27.8rem;
    max-width: 43.1rem;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    grid-template-rows: max-content max-content max-content 1fr;
  }

  .more {
    transition-duration: 0.3s;
  }

  .progress-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.6rem;
    span {
      display: flex;
      align-items: center;
      font-family: "Gilroy-Regular";
      justify-self: center;
      color: $basic-black;
      font-size: 1.4rem;
    }
    b {
      font-size: 1.4rem;
      color: $basic-black;
      font-family: "Gilroy-Bold";
    }
  }

  .progress-tab__cover {
    position: absolute;
    width: 0;
    height: 0;
  }

  .progress-isDisable {
    span,
    b {
      color: $placeholder-text-clr;
    }
  }

  .progress-tab__summary {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;
    padding: 1.6rem 1.6rem;
    background-color: $brand-clr-5;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba($color: $brand-clr-1, $alpha: 0.3);
  }

  .progress-tab__summary--more {
    padding: 2.6rem 1.6rem;
    .summary-item {
      grid-template-columns: 1fr 1fr;
    }
    .summary-item__btn {
      border-radius: 1rem;
      font-size: 1.4rem;
      width: 19.6rem;
      height: 4rem;
    }
  }

  .summary-item__btn {
    transition-duration: 0.3s;
    transition-property: background-color, color;
    font-family: "Gilroy-Bold";
    font-size: 1rem;
    padding: 0.8rem 2.5rem;
    border-radius: 2rem;
    color: $brand-clr-1;
    background-color: $basic-white;
    filter: drop-shadow(0 0 1rem rgba(117, 69, 201, 0.4));
    &:hover {
      background-color: $brand-clr-1;
      color: $basic-white;
    }
  }

  .summary-item {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    column-gap: 1rem;
  }

  .summary-item__left {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
    span:first-child {
      font-family: "Gilroy-Bold";
      color: $basic-black;
      font-size: 1.3rem;
    }
    span:last-child {
      font-size: 0.9rem;
      color: $brand-clr-1;
    }
  }

  .calendar-box {
    justify-self: center;
    width: 27.8rem;
    grid-row-start: 2;
    grid-row-end: 4;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba($color: $brand-clr-1, $alpha: 0.3);
    height: min-content;
    max-height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: max-content 1fr;
  }

  #sweetCalendar .container .body .day-container {
    height: 2.5rem;
  }

  .home__collapse {
    background-color: $brand-clr-3;

    .notify-text {
      color: $basic-black;
      text-align: center;
    }

    .m-collapse__header {
      padding: 1.2rem 2.4rem 1.2rem 4rem;
      background-color: transparent;
    }

    .m-collapse__header-icon {
      color: $brand-clr-1;
    }

    .header__text {
      color: $basic-black;
      font-family: "Gilroy-Bold";
      font-size: 1.4rem;
    }

    .body__content {
      padding: 0 1.6rem 1.6rem 1.6rem !important;
      display: grid;
      row-gap: 0.8rem;
      height: 100%;
    }

    .m-collapse__body::-webkit-scrollbar {
      width: 1rem;
    }
  }

  .home__collapse--progress {
    border-radius: 1rem;
  }

  .collapse-calendar--inner {
    border-radius: 1rem;
    background-color: #4b5aca;

    .m-collapse__header {
      padding: 1rem 0.8rem 1.1rem 0.8rem;
      background-color: transparent;
    }

    .header__text {
      display: grid;
    }

    span,
    b {
      font-size: 1.4rem;
      color: $basic-white;
    }

    span {
      font-family: "Gilroy-Regular";
    }

    b {
      text-transform: uppercase;
      font-family: "Gilroy-Bold";
    }

    .m-collapse__header-icon {
      color: $basic-white;
      align-self: flex-start;
    }

    .body__content {
      display: grid;
      padding: 0 !important;
    }

    .content__wrp {
      padding: 0 1rem 1.2rem 1rem;
      display: grid;
    }

    .content__link {
      color: $basic-black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Gilroy-Bold";
      font-size: 1.4rem;
      background-color: $basic-white;
      padding: 0.8rem 1rem;
    }

    .m-icon--arrow {
      transform: rotate(180deg);
    }
  }

  .home__collapse--advertisement {
    border-radius: 1rem;
    background-color: $brand-clr-1;

    .m-collapse__header {
      column-gap: 0.8rem;
      padding: 0.9rem 0.9rem 0.9rem 1.6rem;
    }

    .m-collapse__body {
      background-color: #f2f3f9;
    }

    .m-collapse__header-icon {
      color: $basic-white;
    }

    .content__text {
      overflow-wrap: anywhere;
      font-family: "ProximaNova-Regular";
      font-size: 1.2rem;
      color: $basic-black;
    }

    .header__text {
      b,
      span {
        color: $basic-white;
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .home {
    .home__left-section,
    .home-right-section {
      max-width: 100%;
    }
  }
}

@include breakPoints(md) {
  .home {
    grid-template-columns: 1fr;
    .flex-container {
      flex-wrap: wrap;
    }
    .home__content {
      border-radius: 0;
    }
  }
}
</style>
