<template>
    <div :class="$style.ElectronicJournal">
        <h4 :class="$style.title">Электронный журнал</h4>

        <listOfCourses
            :class="$style.listOfCourses"
            :list="recordBook"
            :disabled="!!journalNote"
            v-model="selectedSemester"
        />

        <transition name="fade">
            <button
                v-if="journalNote"
                :class="$style.backButton"
                @click="journalNote = null"
            >
                <m-icon name="arrow"/>
            </button>
        </transition>

        <creditTable
            v-if="selectedSemester"
            :class="$style.creditTable"
            :headers="headers"
            :tableData="formatedSemesterData"
            slot-row
            :template-columns="currentTemplateColumns"
            @row-click="rowClickHandler"
        >
            <template #row="row">
                <template v-if="!journalNote">
                    <span
                        v-for="(column, idx) in row"
                        class="table__col"
                        :key="idx"
                    >
                        {{ column }}
                    </span>
                </template>
                <template v-else>
                    <span class="table__col">{{ row.idx }}</span>
                    <span class="table__col">{{ row.theme }}</span>
                    <span class="table__col">{{ row.note }}</span>
                    <span class="table__col">{{ row.date }}</span>
                </template>
            </template>
        </creditTable>

        <h2 v-else class="credit-book__notify">Выберите семестр</h2>
    </div>
</template>

<script>
import listOfCourses from '@/views/EducationalProcess/modules/common/listOfCourses';
import creditTable from '@/views/EducationalProcess/modules/common/creditTable';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ElectronicJournal',

  components: {
    listOfCourses,
    creditTable,
  },

  data() {
    return {
      selectedSemester: null,
      journalData: null,
      journalNote: null,
    };
  },

  computed: {
    ...mapState({
      recordBook: (state) => state.education.recordBook,
      mobileMode: (state) => state.mobileMode,
    }),

    semesterData() {
      return this.journalData?.[this.selectedSemester] || null;
    },

    formatedSemesterData() {
      if (!this.semesterData) {
        return [];
      }

      if (this.journalNote && this.journalNote?.notes) {
        return this.journalNote?.notes?.map((el, idx) => ({ idx: idx + 1, ...el })) || [];
      }

      return Object.keys(this.semesterData).map((key, idx) => [
        idx + 1,
        key,
        this.semesterData[key].gpa,
        this.semesterData[key].absent,
      ]);
    },

    headers() {
      if (!this.journalNote) {
        if (this.mobileMode) {
          return [
            'Дисциплина',
            'Средний балл',
            'Пропуски',
          ];
        }
        return [
          '№',
          'Дисциплина',
          'Средний балл',
          'Пропуски',
        ];
      }
      return [
        '№',
        'Тема',
        'Оценка',
        'Дата',
      ];
    },

    currentTemplateColumns() {
      if (!this.journalNote) {
        return this.mobileMode ? '63% 15% 12%' : '2% 67% 15% 12%';
      }
      return '6% 60% 19% 11%';
    },
  },

  async created() {
    try {
      const promises = [this.axios.get('/api/journal')];

      if (!this.recordBook) {
        promises.push(this.getRecordBook());
      }

      const [journalRes] = await Promise.all(promises);

      this.journalData = journalRes?.data?.journalNotes || {};
    } catch (e) {
      console.warn('Failed fetch ElectronicJournal/created/getJournal', e);
    }
  },

  methods: {
    ...mapActions({
      getRecordBook: 'education/getRecordBook',
    }),

    rowClickHandler(event) {
      const id = this.semesterData?.[event[1]]?.discipline_id || null;

      if (id) {
        this.getDiscipline(id);
      }
    },

    async getDiscipline(id) {
      try {
        const { data, status } = await this.axios.get(`/api/journal-discipline/${this.selectedSemester}/${id}`);

        if (status === 200) {
          this.journalNote = data?.journalNotes || null;
        }
      } catch (e) {
        console.warn(e);
      }
    },

    getJournal() {
      try {
        return this.axios.get('/api/journal');
      } catch (e) {
        console.warn('Failed fetch ElectronicJournal/getJournal', e);
      }
    },
  },
};
</script>

<style lang="scss" module>
    .ElectronicJournal {
        padding-right: 3rem;
    }

    .title {
        font-size: 1.4rem;
        color: $basic-black;

        @include breakPoints(md) {
            display: none;
        }
    }

    .listOfCourses {
        margin-bottom: 5.9rem;
    }

    .creditTable {
        & :global(.table__body .table__row) {
            transition: .3s background-color;
            cursor: pointer;

            &:hover {
                background-color: $placeholder-text-clr;
            }
        }

        & :global(.table__body .table__row span:nth-child(2) ) {
            justify-self: flex-start;
        }
    }

    .noteAndDateCol {
        display: grid;
        width: 100%;
        grid-column: 3/5;
    }

    .noteAndDate {
        display: grid;
        grid-template-columns: 3.5fr 2fr;
        column-gap: 1rem;
        width: 100%;

        span {
            display: block;

            &:first-child {
                justify-self: center;
            }

            &:last-child {
                justify-self: flex-start;
            }
        }
    }

    .backButton {
        margin-bottom: 2rem;
        background-color: transparent;
    }
</style>
